import React from 'react';
import PropTypes from 'prop-types';

import Item from '../Item';

const Articles = ({ articles }) => (
  <ul className="article-list">
    {articles.map((article) => (
      <li key={article.id}>
        <Item article={article} />
      </li>
    ))}
  </ul>
);

Articles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.string,
      author: PropTypes.string,
    }),
  ).isRequired,
};

export default Articles;
