import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Layout.
import Layout, { Seo } from '../../../Layout';
import PageSection from '../../../Elements/PageSection';
import LinkButton from '../../../Elements/LinkButton';
import Cover from '../../../Elements/Cover';

// Components.
import List from '../components/List';
import withTranslations from '../../../../i18n/withTranslations';

const Archive = ({ pageContext: { articles, next, prev } }) => {
  const { t } = useTranslation('componentNieuws');

  return (
    <Layout className="news">
      <Seo title={t('seoTitle')} />
      <Cover>
        <PageSection center className="articles">
          <h1>{t('title')}</h1>
          <List articles={articles} />
          <div className="nav">
            {prev === 0 && (
              <LinkButton className="prev" to="/over-ons/nieuws">
                {t('prev')}
              </LinkButton>
            )}
            {prev !== 0 && prev !== -1 && (
              <LinkButton className="prev" to={`/over-ons/nieuws/archief/${prev}`}>
                {t('prev')}
              </LinkButton>
            )}
            {next !== -1 && (
              <LinkButton className="next" to={`/over-ons/nieuws/archief/${next}`}>
                {t('next')}
              </LinkButton>
            )}
          </div>
        </PageSection>
      </Cover>
    </Layout>
  );
};

Archive.propTypes = {
  pageContext: PropTypes.shape({
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        title: PropTypes.string,
        body: PropTypes.string,
        author: PropTypes.string,
      }),
    ),
    next: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    prev: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  }).isRequired,
};

export default withTranslations(Archive);
